@use 'src/assets/scss/colors';
@use 'src/assets/scss/mixins/breakpoints';

.filter-bg-container {
  background-color: colors.$bg-custom !important;
}

.filter-text-gray {
  color: colors.$qaroni-gray-dark !important;
}

.filter-text-gray-2 {
  color: colors.$qaroni-gray-dark-2 !important;
}

.filter-card-merchant {
  gap: 12px;
  padding: 12px;
}

.filter-merchant-info {
  .mat-h3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  .mat-h4 {
    font-size: 12px;
    line-height: 15.6px;
    font-weight: 300;
  }
}

.filter-card-btn-action {
  gap: 8px;
}

.filter-btn-rounded {
  border-radius: 20px !important;
}
