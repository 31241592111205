@use 'src/assets/scss/colors';

.mdc-notched-outline__notch {
  border-right: none;
}

.mat-mdc-form-field {
  width: 100%;
}

.mat-mdc-form-field.ff-no-bottom-space {
  .mat-mdc-form-field-wrapper {
    margin-bottom: -1.25em;
  }
}

@supports (-moz-appearance: none) {
  .mat-mdc-form-field.moz-suffix {
    .mat-mdc-form-field-suffix {
      top: 0.6em;
    }
  }
}

.mat-mdc-checkbox {
  color: colors.$input-color;
  font-size: 13px;
  font-weight: 400;

  .mat-mdc-checkbox-layout {
    white-space: normal !important;

    .mat-mdc-checkbox-inner-container {
      margin-top: 4px !important;
    }
  }
}

.mat-mdc-select-panel {
  mat-option.mat-option {
    height: unset;
    color: colors.$input-color;
    font-size: 13.5px;
    line-height: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .mat-mdc-option-text {
    white-space: normal;
  }
}

.mat-mdc-form-field.qaroni-form-field-suffix-end {
  .mat-mdc-form-field-icon-suffix {
    align-self: end;
  }
}
