@mixin transition-all($time: 350ms) {
  transition: all $time ease;
}

@mixin transition-color($time: 350ms) {
  transition: color $time ease;
}

@mixin transition-background-color($time: 250ms) {
  transition: background-color $time ease;
}

@mixin transition-font-weight($time: 350ms) {
  transition: font-weight $time ease;
}
