:root {
  --qaroni-primary: #7ebec5;
  --qaroni-primary-50: #c1d0cc;
  --qaroni-primary-100: #c1d0cc;
  --qaroni-primary-200: #a8cac6;
  --qaroni-primary-300: #9bc7c6;
  --qaroni-primary-400: #8dc4c6;
  --qaroni-primary-500: #78a9bf;
  --qaroni-primary-600: #7ebec5;
  --qaroni-primary-700: #7294b9;
  --qaroni-primary-800: #6d7fb2;
  --qaroni-primary-900: #676aac;
  --qaroni-primary-A100: #565f7a;
  --qaroni-primary-A200: #392d4d;
  --qaroni-primary-A400: #352540;
  --qaroni-primary-A700: #2f1d34;

  --qaroni-accent: #003e7f;
  --qaroni-accent-50: #e2f0f8;
  --qaroni-accent-100: #b8d9ef;
  --qaroni-accent-200: #a3dfff;
  --qaroni-accent-300: #64a9da;
  --qaroni-accent-400: #4598d4;
  --qaroni-accent-500: #003e7f;
  --qaroni-accent-600: #217bc1;
  --qaroni-accent-700: #176ab0;
  --qaroni-accent-800: #0f599e;
  --qaroni-accent-900: #003d7f;
  --qaroni-accent-A100: #ffffff;
  --qaroni-accent-A200: #ffffff;
  --qaroni-accent-A400: #cfe8ff;
  --qaroni-accent-A700: #b6dcff;

  --qaroni-warn: #f06c9b;
  --qaroni-warn-50: #fdedf3;
  --qaroni-warn-100: #fbd3e1;
  --qaroni-warn-200: #f8b6cd;
  --qaroni-warn-300: #f598b9;
  --qaroni-warn-400: #f282aa;
  --qaroni-warn-500: #f06c9b;
  --qaroni-warn-600: #ee6493;
  --qaroni-warn-700: #ec5989;
  --qaroni-warn-800: #e94f7f;
  --qaroni-warn-900: #e53d6d;
  --qaroni-warn-A100: #ffffff;
  --qaroni-warn-A200: #ffffff;
  --qaroni-warn-A400: #ffccda;
  --qaroni-warn-A700: #ffb3c7;

  --qaroni-secondary: #506c64;
  --qaroni-secondary-50: #eaedec;
  --qaroni-secondary-100: #cbd3d1;
  --qaroni-secondary-200: #a8b6b2;
  --qaroni-secondary-300: #859893;
  --qaroni-secondary-400: #6a827b;
  --qaroni-secondary-500: #506c64;
  --qaroni-secondary-600: #49645c;
  --qaroni-secondary-700: #405952;
  --qaroni-secondary-800: #374f48;
  --qaroni-secondary-900: #273d36;
  --qaroni-secondary-A100: #88ffdb;
  --qaroni-secondary-A200: #55ffcc;
  --qaroni-secondary-A400: #22ffbd;
  --qaroni-secondary-A700: #08ffb5;

  --qaroni-tertiary: #66101f;
  --qaroni-tertiary-50: #ede2e4;
  --qaroni-tertiary-100: #d1b7bc;
  --qaroni-tertiary-200: #b3888f;
  --qaroni-tertiary-300: #945862;
  --qaroni-tertiary-400: #7d3441;
  --qaroni-tertiary-500: #66101f;
  --qaroni-tertiary-600: #5e0e1b;
  --qaroni-tertiary-700: #530c17;
  --qaroni-tertiary-800: #490912;
  --qaroni-tertiary-900: #38050a;
  --qaroni-tertiary-A100: #ff6f76;
  --qaroni-tertiary-A200: #ff3c46;
  --qaroni-tertiary-A400: #ff0915;
  --qaroni-tertiary-A700: #ef000b;

  --qaroni-color-primary: #4b5c92;
  --qaroni-color-on-primary: #ffffff;
  --qaroni-color-primary-container: #dbe1ff;
  --qaroni-color-on-primary-container: #00174b;

  --qaroni-color-accent: #236488;
  --qaroni-color-on-accent: #ffffff;
  --qaroni-color-accent-container: #c7e7ff;
  --qaroni-color-on-accent-container: #001e2e;

  --qaroni-color-warn: #8c4a5f;
  --qaroni-color-on-warn: #ffffff;
  --qaroni-color-warn-container: #ffd9e2;
  --qaroni-color-on-warn-container: #3a071c;

  --qaroni-color-secondary: #086b5a;
  --qaroni-color-on-secondary: #ffffff;
  --qaroni-color-secondary-container: #a1f2dd;
  --qaroni-color-on-secondary-container: #00201a;

  --qaroni-color-tertiary: #8f4a4f;
  --qaroni-color-on-tertiary: #ffffff;
  --qaroni-color-tertiary-container: #ffdada;
  --qaroni-color-on-tertiary-container: #3b0810;

  --qaroni-color-background: #fcfcff;
  --qaroni-color-on-background: #fff;

  --qaroni-color-success: #155724;
  --qaroni-color-success-50: #e3ebe5;
  --qaroni-color-success-100: #d4edda;
  --qaroni-color-success-200: #8aab92;
  --qaroni-color-success-300: #5b8966;
  --qaroni-color-success-400: #387045;
  --qaroni-color-success-500: #155724;
  --qaroni-color-success-600: #124f20;
  --qaroni-color-success-700: #0f461b;
  --qaroni-color-success-800: #0c3c16;
  --qaroni-color-success-900: #062c0d;
  --qaroni-color-success-A100: #66ff7b;
  --qaroni-color-success-A200: #33ff4f;
  --qaroni-color-success-A400: #00ff23;
  ---qaroni-color-success-A700: #00e620;

  --qaroni-color-info: #0c5460;
  --qaroni-color-info-50: #e2eaec;
  --qaroni-color-info-100: #d1ecf1;
  --qaroni-color-info-200: #86aab0;
  --qaroni-color-info-300: #558790;
  --qaroni-color-info-400: #306e78;
  --qaroni-color-info-500: #0c5460;
  --qaroni-color-info-600: #0a4d58;
  --qaroni-color-info-700: #08434e;
  --qaroni-color-info-800: #063a44;
  --qaroni-color-info-900: #032933;
  --qaroni-color-info-A100: #6bdaff;
  --qaroni-color-info-A200: #38cdff;
  --qaroni-color-info-A400: #05c1ff;
  --qaroni-color-info-A700: #00b0eb;

  --qaroni-color-danger: #dc3545;
  --qaroni-color-danger-50: #fbe7e9;
  --qaroni-color-danger-100: #f5c2c7;
  --qaroni-color-danger-200: #ee9aa2;
  --qaroni-color-danger-300: #e7727d;
  --qaroni-color-danger-400: #e15361;
  --qaroni-color-danger-500: #dc3545;
  --qaroni-color-danger-600: #d8303e;
  --qaroni-color-danger-700: #d32836;
  --qaroni-color-danger-800: #ce222e;
  --qaroni-color-danger-900: #c5161f;
  --qaroni-color-danger-A100: #fff6f7;
  --qaroni-color-danger-A200: #ffc3c6;
  --qaroni-color-danger-A400: #ff9095;
  --qaroni-color-danger-A700: #ff777c;

  --qaroni-color-warning: #ffc107;
  --qaroni-color-warning-50: #fff8e1;
  --qaroni-color-warning-100: #ffecb5;
  --qaroni-color-warning-200: #ffe083;
  --qaroni-color-warning-300: #ffd451;
  --qaroni-color-warning-400: #ffca2c;
  --qaroni-color-warning-500: #ffc107;
  --qaroni-color-warning-600: #ffbb06;
  --qaroni-color-warning-700: #ffb305;
  --qaroni-color-warning-800: #ffab04;
  --qaroni-color-warning-900: #ff9e02;
  --qaroni-color-warning-A100: #ffffff;
  --qaroni-color-warning-A200: #fffaf2;
  --qaroni-color-warning-A400: #ffe4bf;
  --qaroni-color-warning-A700: #ffd9a6;
}
