@use 'src/assets/scss/mixins/breakpoints';

.style-close-dialog {
  position: relative !important;
  overflow: hidden;

  .mat-mdc-dialog-container {
    padding: 35px 45px 50px 45px;
    border-radius: 2px;

    .qaroni-close-icon.mat-mdc-button {
      float: right;
      z-index: 10;
      margin-top: 8px;
    }

    .mat-mdc-dialog-title {
      font-size: 16px !important;
      line-height: 24px !important;

      @include breakpoints.md {
        font-size: 20px !important;
        line-height: 32px !important;
      }
    }

    .qaroni-title-container {
      margin-top: 12px !important;
      margin-bottom: 12px !important;

      @include breakpoints.md {
        margin-top: 24px !important;
        margin-bottom: 24px !important;
      }
    }

    .qaroni-mat-title {
      font-size: 12px !important;
      line-height: 20px;

      @include breakpoints.md {
        font-size: 16px !important;
        line-height: 32px !important;
      }
    }
  }
}
