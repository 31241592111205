@use 'src/assets/scss/_colors.scss';

.bg-comments {
  background: #f8f8f8;
}

.chat-item-text {
  color: #000;
}

.text-start {
  text-align: start;
}

.text-end {
  text-align: end;
}

.max-w90 {
  max-width: 90%;
}

.content-comments {
  max-height: 400px;
  overflow: auto;
}

.item-comments {
  background-color: transparent !important;
  border: 0px !important;
}

.comments-primary {
  color: colors.$theme-primary-700;
  background-color: lighten(colors.$snackbar-primary, 40%);
  margin-right: 20%;
}

.comments-green {
  color: colors.$theme-primary-700;
  background-color: lighten(colors.$snackbar-success, 45%);
  margin-left: 20%;
}

.comments-primary::before {
  content: '';
  position: absolute;
  top: 0;
  left: -10px;
  width: 20px;
  height: 20px;
  color: colors.$theme-primary-700;
  background: linear-gradient(
    225deg,
    lighten(colors.$snackbar-primary, 40%) 0%,
    lighten(colors.$snackbar-primary, 40%) 50%,
    transparent 50%,
    transparent
  );
}

.comments-green::before {
  content: '';
  position: absolute;
  top: 0;
  right: -10px;
  width: 20px;
  height: 20px;
  color: colors.$theme-primary-700;
  background: linear-gradient(
    135deg,
    lighten(colors.$snackbar-success, 45%) 0%,
    lighten(colors.$snackbar-success, 45%) 50%,
    transparent 50%,
    transparent
  );
}

.comments-green {
  .customer-icons {
    .icons {
      color: lighten(colors.$snackbar-success, 25%);
      height: auto;
      width: 20px;
      font-size: 18px;
    }
  }
}

.comments-primary {
  .customer-icons {
    .icons {
      color: lighten(colors.$snackbar-primary, 25%);
      height: auto;
      width: 20px;
      font-size: 18px;
    }
  }
}

.done-icon {
  height: 20px;
  width: 20px;
  font-size: 18px;
}

.content-btns-option {
  right: 0px;
  background-color: rgba(0, 0, 0, 0.12);
}

.btn-option-img {
  width: 35px;
  height: 35px;
  border: none;
  outline: none;
  background-color: transparent;

  mat-icon {
    width: 20px;
    height: 20px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

///////////////////////////////////////////////////
///
/// Styles for skeleton comments
///
//////////////////////////////////////////////////

.skeleton-container {
  gap: 20px;
}

.skeleton-box {
  height: 75px;
  width: 75%;
}

.skeleton {
  background-color: colors.$bg-skeleton;
  background-image: linear-gradient(
    90deg,
    rgba(#fff, 0),
    rgba(#fff, 0.5),
    rgba(#fff, 0)
  );
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  animation: shine 1s ease infinite;
}

@keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}
