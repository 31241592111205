@font-face {
  font-family: 'Rubik';
  src: url('../fonts/Rubik/Rubik-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('../fonts/Rubik/Rubik-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('../fonts/Rubik/Rubik-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('../fonts/Rubik/Rubik-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cunia';
  src: url('../fonts/Cunia/Cunia.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
