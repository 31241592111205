$theme-primary-500: #005eb8;
$theme-primary-700: #002855;

$theme-accent-500: #002855;

$bg-footer: #002855;

$link: #1b75bc;
$link-dark: #003e7f;

$qbono-one: #477aa5;
$qbono-one-contrast: #ffffff;

$qbono-two: #ab6194;
$qbono-two-contrast: #ffffff;

$qbono-three: #5d97af;
$qbono-three-contrast: #ffffff;

$amount-card-one: #38908f;
$amount-card-one-contrast: #ffffff;
$amount-card-two: #e58b88;
$amount-card-two-contrast: #ffffff;
$amount-card-three: #84b4c8;
$amount-card-three-contrast: #ffffff;

// ==========================================================================================
// De aquí para abajo son colores por default,
// no debería ser necesario moverlos
// ==========================================================================================

$link-warn: #bf4f64;
$link-white: #ffffff;

$danger-red: #f44336;

$text-disabled: #939ba2;

$card-bg: #fcfcfc;
$card-border: #e9e9e9;

$input-color: #515151;

$table-bg: #fcfcfc;
$table-paginator: #fcfcfc;

$image-default-bg: #e0e0e0;
$image-default-border: #aaaaaa;

$snackbar-primary: #2196f3;
$snackbar-success: #4caf50;
$snackbar-warning: #ff9800;
$snackbar-danger: #f44336;

$snackbar-secondary: #475569;
$snackbar-info: #0dcaf0;
$snackbar-light: #d1d5db;
$snackbar-dark: #212529;

//===========================================================================================
// Para el preview del branch de merchant
//===========================================================================================

$bg-custom: #f0f3f8;
$qaroni-btn-search: #ebf0f5;
$qaroni-gray-dark-0: #dce1e9;
$qaroni-gray-dark: #556176;
$qaroni-gray-dark-2: #3a4454;
$qaroni-border-gray: #a5adb4;

$bg-skeleton: #e2e5e7;
