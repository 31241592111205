@use 'sass:map';

$colors: ();

$words: ('primary', 'accent', 'warn', 'secondary', 'tertiary');

$variants: (
  '',
  '-50',
  '-100',
  '-200',
  '-300',
  '-400',
  '-500',
  '-600',
  '-700',
  '-800',
  '-900',
  '-A100',
  '-A200',
  '-A400',
  '-A700'
);

$colorsMap: ();

@each $word in $words {
  $aux: ();

  @each $var in $variants {
    $aux: map.set($aux, '#{$word}#{$var}', var(--qaroni-#{$word}#{$var}));
  }

  $colorsMap: map.merge($colorsMap, $aux);
}

$colors: map.merge($colors, $colorsMap);

@each $name, $color in $colors {
  .qaroni-bg-#{$name} {
    background-color: $color;
  }
  .qaroni-text-#{$name} {
    color: $color;
  }
}

@each $w in $words {
  .qaroni-#{$w} {
    background-color: var(--qaroni-color-#{$w});
  }
  .qaroni-#{$w}-text {
    color: var(--qaroni-color-#{$w});
  }
  .qaroni-on-#{$w} {
    background-color: var(--qaroni-color-on-#{$w});
  }
  .qaroni-on-#{$w}-text {
    color: var(--qaroni-color-on-#{$w});
  }
  .qaroni-#{$w}-container {
    background-color: var(--qaroni-color-#{$w}-container);
  }
  .qaroni-#{$w}-container-text {
    color: var(--qaroni-color-#{$w}-container);
  }
  .qaroni-on-#{$w}-container {
    background-color: var(--qaroni-color-on-#{$w}-container);
  }
  .qaroni-on-#{$w}-container-text {
    color: var(--qaroni-color-on-#{$w}-container);
  }
}

.qaroni-background {
  background-color: var(--qaroni-color-background);
}
.qaroni-background-text {
  color: var(--qaroni-color-background);
}
.qaroni-on-background {
  background-color: var(--qaroni-color-on-background);
}
.qaroni-on-background-text {
  color: var(--qaroni-color-on-background);
}
