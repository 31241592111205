.qaroni-text-size-9 {
  font-size: 9px;
}

.qaroni-line-height-10 {
  line-height: 10px;
}

.qaroni-title-legal {
  font-size: 20px;
  margin-bottom: 16px;
  margin-top: 10px;
}

.gap-10 {
  gap: 10px;
}
