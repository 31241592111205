@use 'src/assets/scss/mixins/buttons';
@use 'src/assets/scss/colors';

.mat-mdc-flat-button {
  padding: 0px 25px !important;
}

// ==========================================================================================
// Colors
// ==========================================================================================

@include buttons.qaroni-build-mat-color-button(
  'mat-shop',
  colors.$qbono-one,
  colors.$qbono-one-contrast
);

@include buttons.qaroni-build-mat-color-button(
  'mat-hostelry',
  #ab6194,
  #ffffff
);

@include buttons.qaroni-build-mat-color-button('mat-culture', #5d97af, #ffffff);
