@use 'src/assets/scss/mixins/transitions';
@use 'src/assets/scss/colors';

%cursor-pointer-transition-color {
  cursor: pointer;
  @include transitions.transition-color;
}

.qaroni-link {
  @extend %cursor-pointer-transition-color;
  color: colors.$link !important;

  &:hover {
    color: scale-color($color: colors.$link, $lightness: -25%) !important;
    text-decoration: none;
  }
}

.qaroni-link-dark {
  @extend %cursor-pointer-transition-color;
  color: colors.$link-dark !important;

  &:hover {
    color: scale-color($color: colors.$link-dark, $lightness: -25%) !important;
    text-decoration: none;
  }
}

.qaroni-link-warn {
  @extend %cursor-pointer-transition-color;
  color: colors.$link-warn !important;

  &:hover {
    color: scale-color($color: colors.$link-warn, $lightness: -25%) !important;
    text-decoration: none;
  }
}

.qaroni-link-white {
  @extend %cursor-pointer-transition-color;
  color: colors.$link-white !important;

  &:hover {
    color: scale-color($color: colors.$link-white, $lightness: -5%) !important;
    text-decoration: none;
  }
}
