@use 'src/assets/scss/_colors.scss';

.tag-danger {
  padding: 5px 10px;
  background-color: colors.$snackbar-danger;
  border-radius: 4px;
  color: #fff;
}

.tag-primary {
  padding: 5px 10px;
  background-color: colors.$snackbar-primary;
  border-radius: 4px;
  color: #fff;
}
