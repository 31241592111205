.mdc-linear-progress__bar-inner {
  border-color: var(--qaroni-accent-900) !important;
}

.mdc-linear-progress__buffer-bar {
  background-color: var(--qaroni-accent-200) !important;
}

.grecaptcha-badge {
  z-index: 999 !important;
}
