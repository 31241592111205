@use 'src/assets/scss/colors';

%qaroni-card-base {
  border-radius: 1rem;

  box-shadow: none;

  background-color: colors.$card-bg;
  border: 2px solid colors.$card-border;
}

.mat-mdc-card.qaroni-card-one {
  @extend %qaroni-card-base;
}

.mat-mdc-card.qaroni-card-two {
  padding: 32px 24px 20px 24px;
  @extend %qaroni-card-base;

  .text-card-bottom {
    font-size: 1.125rem;
  }
}

.mat-mdc-card.card-bg-shop {
  background-color: lighten(colors.$qbono-one, 52%);
  border: 1px solid lighten(colors.$qbono-one, 47%);
}

.mat-mdc-card.card-bg-hostelry {
  background-color: lighten(colors.$qbono-two, 46%);
  border: 1px solid lighten(colors.$qbono-two, 41%);
}

.mat-mdc-card.card-bg-culture {
  background-color: lighten(colors.$qbono-three, 45%);
  border: 1px solid lighten(colors.$qbono-three, 40%);
}
