@use 'src/assets/scss/mixins/breakpoints';
@use 'src/assets/scss/colors';

.snack-margin-top {
  margin-top: 56px;

  @include breakpoints.rwbp(600) {
    margin-top: 64px;
  }
}

.mat-mdc-snack-bar-container {
  &.qaroni-primary-snackbar {
    border-top: 6px solid colors.$snackbar-primary;
    border-bottom: 6px solid colors.$snackbar-primary;
  }

  &.qaroni-success-snackbar {
    border-top: 6px solid colors.$snackbar-success;
    border-bottom: 6px solid colors.$snackbar-success;
  }

  &.qaroni-warning-snackbar {
    border-top: 6px solid colors.$snackbar-warning;
    border-bottom: 6px solid colors.$snackbar-warning;
  }

  &.qaroni-danger-snackbar {
    border-top: 6px solid colors.$snackbar-danger;
    border-bottom: 6px solid colors.$snackbar-danger;
  }
}
